import React, { lazy, Suspense } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
const Home = lazy(() => import("./components/homepage"));

const App = () => {
  return (
    <Router>
      <div>
        <Suspense
          fallback={
            <div className="vh-100 d-flex align-items-center justify-content-center">
              Loading...
            </div>
          }
        >
          <Switch>
            <Route exact path="/:roomName">
              <Home />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
